import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerAbout from './BannerAbout'
import SectionTitleOne from './SectionTitleOne'
import Appointment from './Appointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';
import Qulification from './Qulification'

import { Helmet } from 'react-helmet';

class AboutUs extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Top Sleep Specialist Doctor In Hyderabad | Sleep therapeutics</title>
                    <meta name='description' content='Meet the Top Sleep Specialist Doctor in Hyderabad to improve your sleep. Our team provides advanced diagnostics and personalized care for your unique sleep needs.' />
                </Helmet>
                <BannerAbout pageTitle='About Us'/>

                <section className="about-page-top-content-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className="section-text-wrap">
                                    <h1>What is Sleep Therapeutics?</h1>
                                    <p>We are a team of sleep specialists dedicated to helping people overcome their sleep issues and lead a happy, healthy and fulfilling life. Being the first niche sleep centre in Telangana and Andhra Pradesh, our state-of-the-art sleep labs help us in our integrated and holistic approach to finding sleep solutions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="patient-focused-wrap section-padding text-white section-bg text-center vBlue315">
                    <div className="container">
                        
                        <SectionTitleOne BigTitle='Our Unique Approach' />

                        <div className="row equal-cols">
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon">
                                        <img src={require ("../assets/img/whatsinaname/Icons/AboutIcon01.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">Simple Registration</a></h3>
                                    <p>Our team makes your registration process a breeze.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon">
                                        <img src={require ("../assets/img/whatsinaname/Icons/AboutIcon02.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">Very Less Waiting Time</a></h3>
                                    <p>We respect your precious time, so you won’t have to wait long for consultations.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon">
                                        <img src={require ("../assets/img/whatsinaname/Icons/AboutIcon03.png") } alt="" />
                                    </div>
                                    <h3><a href=".#">Quick Turnaround Time</a></h3>
                                    <p>Your test or diagnostic results are delivered very quickly.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="promo-content-section promo-bg section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className='col-lg-10 offset-lg-1'>
                                <div className='row  align-items-center'>
                                <div className="col-lg-6 col-12">
                                <img src={require("../assets/img/whatsinaname/DoctorLanding.jpg")} alt="" />
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    {/* <h2>Dr. Harshini Errabelli</h2>
                                    <p>MD (Sleep Medicine, Critical Care & Respiratory Medicine)</p> */}
                                    <h2>Dr. Harshini Errabelli</h2>
                                    <p className='vNoMarginBottom'>Founder & Director Interventional Pulmonologist and Sleep Specialist</p>
                                    <p className='vNoMarginBottom'>MD (Respiratory, Critical Care & Sleep Medicine)</p>
                                    <p className='vNoMarginBottom'>Fellowship in Interventional Pulmonology (Malaysia)</p>
                                    <p>II Level Master in Interventional Pulmonology (Italy)</p>
                                    <p>With a vast experience in Sleep Medicine, Critical Care and Respiratory Medicine, Dr. Harshini has set out to make a difference in how modern Indian society sleeps. She is an active member of various Indian and international medical associations. They include:</p>
                                </div>
                            </div>
                            <div className='clearfix'></div>
                            <div className='col-12'>
                                <Qulification />
                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="our-achievement-wrap section-bg text-white section-padding mb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-lg-6">
                                <div className="section-text-wrap">
                                    <h1>Our Achievement</h1>
                                    <p>Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He has embraced Cosmetic Dentistry and has redesigned the smiles for thev thousands of patients.</p>
                                    <a href=".#" className="theme-btn mr-lg-3">Appointment</a>
                                    <a href=".#" className="theme-btn border-btn popup-video"><i className="fal fa-play mr-2" />Watch Video</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="achievement-funfact row">
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                end={22}
                                                duration={3.75} />
                                            <p>Patients</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={75}
                                                    duration={3.75} />
                                            <p>Dentist</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={25}
                                                    duration={3.75} />
                                            <p>Awards</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={28}
                                                    duration={3.75} />
                                            <p>Branch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                
                <BlogOne BlogData={BlogData} />
                <Appointment appointmentTitle={"Book a Consultation"} />

            </React.Fragment>
        )
    }
}

export default AboutUs
