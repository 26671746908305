import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomeOne from './Components/HomeOne';
import Header from './Components/Header/Header';
import FooterOne from './Components/Footer/FooterOne';
import AboutUs from './Components/AboutUs';
import PageNotfound from './Components/PageNotfound';
import NewsEvents from './Components/NewsEvents';
import PartnerWithUs from './Components/PartnerWithUs';
import VirtualTour from './Components/VirtualTour';
import TreatmentPackages from './Components/TreatmentPackages';
import Services from './Components/Services';
import FAQs from './Components/FAQs';
import BreatheClinic from './Components/BreatheClinic';
import ServiceDetails from './Components/Page/ServiceDetails';
import DentistDetails from './Components/Page/DentistDetails';
import Blog from './Components/Page/Blog';
import Contact from './Components/Contact';
import Team from './Components/Page/Team';
import BlogDetails from './Components/Page/BlogDetails';
import FooterData from './Components/Data/FooterData';
import './App.css';
import { useState } from 'react';
import ThankYouPage from './Components/thank-you';

function App() {
  return (
    <div className="main-wrapper">
      <Router>
        <Header />
        <Switch>
        <Route exact path='/' render={props => (
          <React.Fragment>
            <HomeOne />
          </React.Fragment>
        )} />

        {/* <Route
          exact path="/*"
          status={404}
          render={props => (
              <PageNotfound />
          )}
        /> */}
        {/* <Route  path="/*" component={PageNotfound} status={404}/> */}
        <Route exact path='/AboutUs' component={AboutUs} />
        <Route path='/NewsEvents' component={NewsEvents} />
        <Route path='/TreatmentPackages' component={TreatmentPackages} />
        <Route path='/Services' component={Services} />
        <Route path='/PartnerWithUs' component={PartnerWithUs} />
        <Route path='/Page/ServiceDetails' component={ServiceDetails} />
        <Route path='/Page/DentistDetails' component={DentistDetails} />
        <Route path='/Page/BlogDetails' component={BlogDetails} />
        <Route path='/Page/Blog' component={Blog} />
        <Route path='/Page/Team' component={Team} />
        <Route path='/Contact' component={Contact} />
        <Route path='/FAQs' component={FAQs} />
        <Route path='/BreatheClinic' component={BreatheClinic} />
        <Route path='/VirtualTour' component={VirtualTour} />
        <Route path='/thank-you' component={ThankYouPage} />
        
        <Route exact path='*' render={props => (
          <React.Fragment>
            <PageNotfound />
          </React.Fragment>
        )} />
        
        
        </Switch>
        <Switch>
        
        </Switch>
        
        
        {/* <Route exact path="/*" component={PageNotfound} status={404}/> */}

		    <FooterOne FooterData={FooterData} />
        
      </Router>
      
    </div>
  );
}

export default App;
