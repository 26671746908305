import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerNewsEvent from './BannerNewsEvent'
import SectionTitleOne from './SectionTitleOne'
import Appointment from './Appointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';
import Qulification from './Qulification'
import SleepNews from './SleepNews'
import GalleryImage from './GalleryImage';
import NewsPublished from './NewsPublished';

import { Helmet } from 'react-helmet';

class NewsEvents extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>News and Gallery | Sleep Therapeutics</title>
                    <meta name='description' content='Discover the latest news and gallery of sleep therapeutics. Find expert insights, innovative treatments, and valuable resources to enhance your sleep health and well-being. Dont miss out on the transformative power of quality sleep – visit Sleep Therapeutics today!' />
                </Helmet>
                <BannerNewsEvent pageTitle='News & Events' />

                <section className="about-page-top-content-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className="section-text-wrap">
                                    <h1>News & Events at Sleep Therapeutics</h1>
                                    <p>Stay updated with the latest news and events from the world of Sleep Therapeutics. Whether it’s a discovery, an event, a dignitary visit, an introduction of new services, our media coverage, etc., find it all here.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <SleepNews />
                <NewsPublished />
                <GalleryImage />
                {/* <Appointment appointmentTitle={"We’re a message away."}  /> */}

            </React.Fragment>
        )
    }
}

export default NewsEvents
