import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ThreeDots } from 'react-loader-spinner';
import { Redirect } from 'react-router-dom';
import '../assets/css/appointment.css';

class Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      submissionSuccess: false,
    };
  }

  // Function to append UTM parameters dynamically

  appendUtmParameters = (formData) => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = ['utm_source', 'utm_keyword', 'utm_network', 'utm_campaign', 'utm_device','','utm_term','utm_medium'];

    utmParams.forEach(param => {
      const value = urlParams.get(param);
      if (value) {
        formData[param] = value ? value :"undefined"; // Appends UTM parameters dynamically
      }
    });
    formData.source = urlParams.get('utm_source') || 'Website';
    formData.utm_source=urlParams.get('utm_source') || 'Website';
    return formData;
  };

  // Form submission handler
  handleSubmit = (values, { setSubmitting, setFieldError }) => {
    this.setState({ loading: true });

    let jsonData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      additional_col3: values.branch,
      additional_col4: values.age,
      additional_col2: values.sleepIssue,
      access_code: 'AE45-C8A5-A80F-0894-0FF5-77B7',
    };

    // Append UTM parameters
    jsonData = this.appendUtmParameters(jsonData);

    // Send form data to the first API (Lead API)
    axios.post('https://growtheye.com/leadApiBeta.php', jsonData)
      .then(response => {
        console.log('CRM Response:', response.data);

        // Send data to logging API
        return axios.post('https://sleeptherapeutics.in/sleep-react-backup/01-Jan-2025/log.php', jsonData);
      })
      .then(logResponse => {
        console.log('Log Response:', logResponse.data);
        this.setState({ submissionSuccess: true });
      })
      .catch(error => {
        console.error('Error in form submission:', error);
        setFieldError('submit', 'There was an error submitting the form.');
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({ loading: false });
      });
  };

  render() {
    const { appointmentTitle, subTitle } = this.props;

    // Redirect to Thank You page on successful submission
    if (this.state.submissionSuccess) {
      return <Redirect to="/thank-you" />;
    }

    // Form validation schema
    const validationSchema = Yup.object({
      name: Yup.string().required('Patient name is required'),
      email: Yup.string().email('Invalid email format').required('Email is required'),
      phone: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits').required('Phone is required'),
      branch: Yup.string().required('Branch is required'),
      age: Yup.number().required('Age is required').positive().integer(),
      sleepIssue: Yup.string().required('Please select a sleep issue'),
    });

    return (
      <section className="appointment-wrapper section-padding" id="consult">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title-one">
                <h1>{appointmentTitle}</h1>
                <h2>{subTitle}</h2>
              </div>
            </div>

            <div className="col-sm-12 col-lg-6">
              <div className="appointment-form">
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    branch: '',
                    age: '',
                    sleepIssue: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={this.handleSubmit}
                >
                  {({ isSubmitting, errors }) => (
                    <Form className="row">
                      <div className="col-lg-12 mb-3">
                        <label htmlFor="name">Patient's Full Name</label>
                        <Field type="text" name="name" className="form-contro" />
                        <ErrorMessage name="name" component="div" className="error-message" />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <label htmlFor="email">Email</label>
                        <Field type="email" name="email" className="form-contro" />
                        <ErrorMessage name="email" component="div" className="error-message" />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <label htmlFor="phone">Phone</label>
                        <Field type="text" name="phone" className="form-contro" />
                        <ErrorMessage name="phone" component="div" className="error-message" />
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label htmlFor="branch">Branch</label>
                        <Field as="select" name="branch" className="form-contro">
                          <option value="">Choose Branch</option>
                          <option value="Kukatpally">Kukatpally</option>
                          <option value="FilmNagar">FilmNagar</option>
                        </Field>
                        <ErrorMessage name="branch" component="div" className="error-message" />
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label htmlFor="age">Patient's Age</label>
                        <Field type="number" name="age" className="form-contro" />
                        <ErrorMessage name="age" component="div" className="error-message" />
                      </div>

                      <div className="col-lg-12 mb-3">
                        <label htmlFor="sleepIssue">What Sleep issue are you facing?</label>
                        <Field as="select" name="sleepIssue" className="form-contro">
                          <option value="">Select Sleep Issue</option>
                          <option value="Sleep Apnea">Sleep Apnea</option>
                          <option value="Snoring">Snoring</option>
                          <option value="Fatigue">Fatigue</option>
                          <option value="Day-time Drowsiness">Day-time Drowsiness</option>
                          <option value="Concentration Issues">Concentration Issues</option>
                          <option value="Difficulty in Breathing">Difficulty in Breathing</option>
                          <option value="Insomnia">Insomnia</option>
                          <option value="Others">Others</option>
                        </Field>
                        <ErrorMessage name="sleepIssue" component="div" className="error-message" />
                      </div>

                      <button type="submit" disabled={isSubmitting} className="submit-btn w-100 blue-btn">
                        {isSubmitting ? (
                          <ThreeDots height="20" width="30" radius="9" color="white" ariaLabel="three-dots-loading" />
                        ) : (
                          'Book Appointment'
                        )}
                      </button>

                      {errors.submit && <div className="error-message">{errors.submit}</div>}
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="col-lg-6">
              <img src={require('../assets/img/whatsinaname/ConsultSpecialists.png')} alt="Consult Specialists" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Appointment;
