import React, { Component } from 'react';
import Slider from 'react-slick';
import SectionTitleOne from '../SectionTitleOne';


class TestimonailOne extends Component {
    render() {

        const settings = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 4000,
            autoplaySpeed: 4000,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]           
        };

        return (
            <section className="testimonial-one-wrapper section-padding">
                <div className="hero-shape service-shape">
                    <img src={require("../../assets/img/shape/rmc.png")} alt="modinatheme" className="shape shape2" />
                    <img src={require("../../assets/img/shape/plusgs.png")} alt="modinatheme" className="shape shape4" />
                    <img src={require("../../assets/img/shape/plusg.png")} alt="modinatheme" className="shape shape6" />
                    <img src={require("../../assets/img/shape/plusr.png")} alt="modinatheme" className="shape shape8" />
                    <img src={require("../../assets/img/shape/sgdot.png")} alt="modinatheme" className="shape shape9" />
                </div>
               





       



        
            <section className="brand-logo-wrapper section-padding">
                <div className="container">
                <SectionTitleOne BigTitle="Testimonials"/>
                    <div className="row">
                        <div className="col-md-12">
                            <Slider className="brand-logo-carousel" {...settings}>
                                <div className="single-brand-logo">
                                <div className="row align-items-center">
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-client-box">
                                <div className="client-image">
                                    <img src={require("../../assets/img/generic-lady.png")} alt="modinatheme" />
                                </div>
                                <h3>Usharani</h3>
                                <span>68, HouseWife</span>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-8 ">
                            <div className="single-review-item">
                                <h5>Awesome Work</h5>
                                <p>“I am very happy with the treatment I received. I couldn’t sleep for the past 20 years, and I went to many doctors. Finally got treated here. The doctor has a lot of patience, and her experience and interest towards treating a patient is her plus point. I felt very much comfortable in the sleep lab. I am highly impressed with the doctor and would recommend the doctor for any sort of sleep problems. The clinic is very hygienic and well-maintained. A homely atmosphere has been created!”</p>
                            </div>
                        </div>
                    </div>
                                </div>
                                <div className="single-brand-logo">
                                <div className="row align-items-center">
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-client-box">
                                <div className="client-image">
                                    <img src={require("../../assets/img/generic-lady.png")} alt="modinatheme" />
                                </div>
                                <h3>Balachander Reddy,</h3>
                                <span>Manager for a construction company</span>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-8 ">
                            <div className="single-review-item">
                                <p>The service was fantastic. The doctor pays attention to every detail. She is well experienced and more importantly, she has the patience to listen to all our problems. She explains everything very clearly. The sleep lab is wonderful, with a homely atmosphere. My sleep levels improved, and my quality of life improved after using the CPAP machine, as suggested by the doctor. She gave me a correct diagnosis and solved my problem accurately without unnecessary medication. The Clinic is very well maintained.</p>
                            </div>
                        </div>
                    </div>
                                </div>
                                <div className="single-brand-logo">
                                <div className="row align-items-center">
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-client-box">
                                <div className="client-image">
                                    <img src={require("../../assets/img/generic-lady.png")} alt="modinatheme" />
                                </div>
                                <h3>Reecha Sree Pallerla</h3>
                                <span></span>
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-8 ">
                            <div className="single-review-item">
                                <p>Fantastic, well hygienic and well-maintained clinic and sleep labs. A homely atmosphere has been created. The best clinic & Doctor for sleep-related issues. Highly recommend it to many people. She is a dietitian too.</p>
                            </div>
                        </div>
                    </div>
                                </div>
                                <div className="single-brand-logo">
                                <div className="row align-items-center">
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-client-box">
                                <div className="client-image">
                                    <img src={require("../../assets/img/generic-lady.png")} alt="modinatheme" />
                                </div>
                                <h3>A. Jalaja, Classical Dancer</h3>
                                {/*<span>68, HouseWife</span>*/}
                            </div>
                        </div>

                        <div className="col-md-6 col-12 col-lg-8 ">
                            <div className="single-review-item">
                                <h5>Awesome Work</h5>
                                <p>My experience thus far has been magnificent, as these things go. Dr. Harshini Errabelli is experienced, very knowledgeable, caring, concerned, and responsive to patient needs. She takes time with patients to promote a level of comfort and understanding that I have not experienced with any other doctor. She does not rush through appointments but sits and spends time with the patient to address all concerns and questions without making one feel guilty for taking up too much time. Also, she doesn’t watch the clock while in the room with you. She is very well versed in all manner of Sleep issues and is current with medical knowledge and treatment.</p>
                            </div>
                        </div>
                    </div>
                                </div>
                                
                            </Slider >
                        </div>
                    </div>
                </div>
            </section>
  






            </section>)
        
    }
}

export default TestimonailOne
