import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerPartnerWithUs from './BannerPartnerWithUs'
import SectionTitleOne from './SectionTitleOne'
import ContactAppointment from './ContactAppointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';
import Qulification from './Qulification'

import { Helmet } from 'react-helmet';

class PartnerWithUs extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Sleep Centre In Hyderabad |  Sleep therapeutics</title>
                    <meta name='description' content='Join our leading Sleep Centre in Hyderabad to shape the future of sleep care. Collaborate with us for sleep health advancements and well-being.' />
                </Helmet>
                <BannerPartnerWithUs pageTitle='Partner With Us' />

                <section className="about-page-top-content-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className="section-text-wrap">
                                    <h1>Collaborate With Us; Envision a Healthy World</h1>
                                    <p>As part of our commitment to helping people regain quality sleep, we are passionate about working with the esteemed medical fraternity to improve the health and well-being of people. It will ensure that people get access to the best solutions for their sleep issues and lead a happier and healthier life.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="patient-focused-wrap section-padding text-white section-bg text-center mb-60 vBlue315">
                    <div className="container">
                        
                        <SectionTitleOne BigTitle='We can work together using the following three models:' />

                        <div className="row equal-cols">
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/PartnerWithUs/Icon01.png") } alt="" />
                                    </div>
                                    {/*<h3><a href=".#">Simple Registration</a></h3>*/}
                                    <p>Doctors who want to refer a patient only for a sleep study can either send a referral letter via email or fill out the form below. They can also send a hard copy of the letter to the patient. We shall schedule a sleep study appointment following this course of action.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/PartnerWithUs/Icon02.png") } alt="" />
                                    </div>
                                    <p>Suppose your patient is undergoing any consultations or testing with us. In that case, we’ll promptly provide you with a detailed report within 8 hours via email or phone, depending on your preference.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="best-service-item-box">
                                    <div className="service-box-icon service-box-icon1">
                                        <img src={require ("../assets/img/whatsinaname/PartnerWithUs/Icon03.png") } alt="" />
                                    </div>
                                    <p>If you’re interested in any of our other services for your patient, you may select and purchase them individually or as part of a package.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                

                {/*<section className="our-achievement-wrap section-bg text-white section-padding mb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-lg-6">
                                <div className="section-text-wrap">
                                    <h1>Our Achievement</h1>
                                    <p>Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He has embraced Cosmetic Dentistry and has redesigned the smiles for thev thousands of patients.</p>
                                    <a href=".#" className="theme-btn mr-lg-3">Appointment</a>
                                    <a href=".#" className="theme-btn border-btn popup-video"><i className="fal fa-play mr-2" />Watch Video</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="achievement-funfact row">
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                end={22}
                                                duration={3.75} />
                                            <p>Patients</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={75}
                                                    duration={3.75} />
                                            <p>Dentist</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={25}
                                                    duration={3.75} />
                                            <p>Awards</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={28}
                                                    duration={3.75} />
                                            <p>Branch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                
                <BlogOne BlogData={BlogData} />
                <ContactAppointment appointmentTitle={"Fill out the form below to communicate with us:"} /> 

            </React.Fragment>
        )
    }
}

export default PartnerWithUs
