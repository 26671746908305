const FooterData = {
    singleWidget: [
        {
       
            id: 1,
            title: "Quicklinks",
            menuItems: [
                {
                    id: 1,
                    url: "/AboutUs",
                    title: "About Us"
                },
                {
                    id: 2,
                    url: "/FAQs",
                    title: "FAQs"
                },
                {
                    id: 3,
                    url: "https://sleeptherapeutics.in/blog/",
                    title: "Blogs"
                },
                {
                    id: 4,
                    url: "/Contact",
                    title: "Contact Us"
                },
                {
                    id: 5,
                    url: ".#",
                    title: "Privacy Policy"
                },
                {
                    id: 6,
                    url: ".#",
                    title: "Terms & Conditions"
                },
                {
                    id: 7,
                    url: "/#consult",
                    title: "Book an Appointment"
                },
            ]
        }
    ],


    copyright: "2023-24 Sleep Therapeutics - A Unit of SVS HealthX",

}


export default FooterData
