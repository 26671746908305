import React, { Component } from 'react'
import AwardsSlide from './AwardsSlide'

class OurSleepSpecialist extends Component {
    render() {
        return (
            <section className="doctors-wrapper section-padding">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6  col-12">
                            <h1 className='vDocHead vMob'>Meet our Founder</h1>
                            <div className="text-center">
                                <img src={require("../assets/img/whatsinaname/DoctorLanding.jpg")} alt="" />
                                {/* <div className='vDocMeet'>
                                    <h3>Dr. Harshini Errabelli</h3>
                                    <p>MD (Sleep Medicine, Critical Care & Respiratory Medicine)</p>
                                </div> */}
                                <div className='vDocMeet'>
                                    <h3>Dr. Harshini Errabelli</h3>
                                    <p>Founder & Director Interventional Pulmonologist and Sleep Specialist</p>
                                    <p>MD (Respiratory, Critical Care & Sleep Medicine)</p>
                                    <p>Fellowship in Interventional Pulmonology (Malaysia)</p>
                                    <p>II Level Master in Interventional Pulmonology (Italy)</p>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-12 col-lg-6 col-12">
                            <div className="appointment-promo-content">
                                <h1 className='vDesktop'>Meet our Founder</h1>
                                <p>Dr. Harshini is an expert in her field of specialisation. This comes from her extensive training at Indian and international institutes of repute. She is also an active member of numerous Indian and international associations in the fields of sleep medicine and pulmonology. Sleep Therapeutics is the result of Dr. Harshini’s noble vision and hard work to bring the best of Sleep Medicine from around the world to Telangana and Andhra Pradesh.</p>
                                <AwardsSlide />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default OurSleepSpecialist
