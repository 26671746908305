import React, { Component } from 'react'
import { Accordion, Card } from "react-bootstrap";
import CountUp from 'react-countup';
import BannerFaqs from './BannerFaqs'
import SectionTitleOne from './SectionTitleOne'
import Appointment from './Appointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';
import Qulification from './Qulification'
import SleepNews from './SleepNews'

class FAQs extends Component {
    render() {
        return (
            <React.Fragment>
                <BannerFaqs pageTitle='FAQs'  />

                <section className="about-page-top-content-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className="section-text-wrap">
                                    <h1>FAQs (Frequently Asked Questions)</h1>
                                    <p>Here are a few FAQs to help you better understand us and our process. Read through them for your basic understanding.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="brand-logo-wrapper vVedioBlock">
                    <div className="container">
                        <div className="row">
                            <div className='col-md-12'>
                            <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                1. What is sleep apnea?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                Sleep apnea is a potentially serious sleep disorder in which breathing repeatedly stops and starts. You might have sleep apnea if you snore loudly and feel tired even after a full night's sleep. The main types of sleep apnea are obstructive sleep apnea and central sleep apnea.
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>

                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                2. What is a sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                                <Card.Body>A sleep study is a test that records data about you while you sleep to help doctors diagnose sleep disorders such as sleep apnea.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                                3. What are the benefits of good sleep?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="2">
                                <Card.Body>Good sleep is essential for physical, emotional, and mental health. It impacts nearly every aspect of the body, affecting the brain, heart, lungs, metabolism, mood, and immune system.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                                4. What are some common sleep disorders?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="3">
                                <Card.Body>Some common sleep disorders include insomnia, sleep apnea, restless leg syndrome, narcolepsy, and REM behaviour disorder.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                                5. How is sleep apnea treated?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="4">
                                <Card.Body>Sleep apnea can be treated with lifestyle changes such as losing weight or quitting smoking, using a continuous positive airway pressure (CPAP) machine to keep the airway open during sleep, or surgery to remove excess tissue from the throat.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                            6. How long does a sleep study take?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="5">
                                <Card.Body>A typical sleep study lasts for about a night.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="6">
                                7. Can I bring my pillow to a sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="6">
                                <Card.Body>Yes. You can. We aim to ensure your sleep environment is comfortable, leading to more accurate results. </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="7">
                                8. What should I wear to a sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="7">
                                <Card.Body>You should wear comfortable clothing to a sleep study, such as pyjamas or loose-fitting clothes.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="8">
                                9. Can I eat or drink before a sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="8">
                                <Card.Body>Our extensive and advanced sleep study test includes all the meals as well. So, ideally, we will take care of all your food requirements.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="9">
                            10. How do I prepare for a sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="9">
                                <Card.Body>To prepare for a sleep study, follow your routine as much as possible and avoid napping during the day. You should also avoid caffeine and alcohol before the test.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="10">
                            11. What happens during a sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="10">
                                <Card.Body>During a sleep study, sensors are attached to your body to record data such as brain activity, eye movements, heart rate, and breathing while you sleep.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="11">
                            12. How do I get the results of my sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="11">
                                <Card.Body>The results of your sleep study will be analysed by a doctor who will then provide you with a diagnosis and treatment plan if necessary.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="12">
                                13. Can I drive home after a sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="12">
                                <Card.Body>Absolutely. You can freshen up at our Sleep Study Centre and go to work or follow your regular routine without any change.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="13">
                                14. Will my insurance cover a sleep study?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="13">
                                <Card.Body>You should check with your insurance company to see if they cover the cost of a sleep study.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="14">
                                15. How much does a sleep study cost?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="14">
                                <Card.Body>The cost of a sleep study varies depending on factors such as location and type of test.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="15">
                                16. Can I have a sleep study at home?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="15">
                                <Card.Body>Yes, some types of sleep studies can be done at home using portable monitoring equipment.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="16">
                                17. What is insomnia?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="16">
                                <Card.Body>Insomnia is difficulty falling asleep or staying asleep even when you have the opportunity to do so.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="17">
                                18. How is insomnia treated?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="17">
                                <Card.Body>Insomnia can be treated with lifestyle changes such as establishing regular bedtimes and wake times or avoiding caffeine and alcohol before bed, cognitive-behavioural therapy, or medication.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="18">
                                19. What is restless leg syndrome?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="18">
                                <Card.Body>Restless leg syndrome is a condition that causes an irresistible urge to move your legs, often accompanied by uncomfortable sensations in the legs.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="19">
                                20. How is restless leg syndrome treated?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="19">
                                <Card.Body>Restless leg syndrome can be treated with lifestyle changes such as regular exercise or avoiding caffeine and alcohol, medication, or iron supplements if iron deficiency is present.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="20">
                                21. What is narcolepsy?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="20">
                                <Card.Body>Narcolepsy is a chronic neurological disorder that affects the brain's ability to regulate the normal cycle of wakefulness and drowsiness, resulting in excessive daytime drowsiness and sudden attacks of muscle weakness known as cataplexy.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="21">
                                22. How is narcolepsy treated?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="21">
                                <Card.Body>Narcolepsy can be treated with medication to improve alertness during the day and suppress cataplexy; scheduled naps; or lifestyle changes such as regular exercise or avoiding caffeine and alcohol.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="22">
                                23. What is REM behaviour disorder?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="22">
                                <Card.Body>REM behaviour disorder is when people physically act out their dreams during the REM (rapid eye movement) stage of their sleep.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="23">
                                24. How is REM behaviour disorder treated?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="23">
                                <Card.Body>REM behaviour disorder can be treated with medication to suppress muscle activity during sleep or lifestyle changes such as avoiding caffeine and alcohol.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="24">
                                25. What are some tips for better sleep hygiene?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="24">
                                <Card.Body>Some tips for better sleep hygiene include establishing regular bedtimes and wake times; creating a comfortable sleep environment; avoiding caffeine, alcohol, and large meals before bed; and engaging in regular exercise.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="25">
                            26. How can I improve my sleep environment?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="25">
                                <Card.Body>You can improve your sleep environment by keeping the room cool, dark, and quiet, using a comfortable mattress and pillows, and minimising noise and light disruptions.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="26">
                            27. Can exercise improve my sleep quality?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="26">
                                <Card.Body>Yes, regular exercise can improve sleep quality by reducing stress and promoting relaxation but exercising before bedtime can affect your sleep.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="27">
                            28. Can diet affect my sleep quality?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="27">
                                <Card.Body>Yes, diet can affect sleep quality. Eating a balanced diet and avoiding large meals, caffeine, and alcohol before bed can improve sleep quality.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="28">
                            29. Can stress affect my sleep quality?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="28">
                                <Card.Body>Yes, stress can affect sleep quality by making it difficult to fall or stay asleep. Managing stress through relaxation techniques or therapy can improve sleep quality.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Accordion.Toggle as={Card.Header} eventKey="29">
                            30. Can medication affect my sleep quality?
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="29">
                                <Card.Body>Yes, some medications can affect sleep quality by causing drowsiness or insomnia as side effects. If you are experiencing difficulty sleeping while taking medication, you should speak with your doctor about potential alternatives.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                            </div>
                        </div>
                    </div>
                </section>
                                    
                    <Appointment appointmentTitle={"Book a Consultation"} />

            </React.Fragment>
        )
    }
}

export default FAQs
