import React, { Component } from 'react'
import SectionTitleOne from './SectionTitleOne'
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

class GalleryImage extends Component {
    render() {
            var img1 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage01.jpg');
            var img2 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage02.jpg');
            var img3 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage03.jpg');
            var img4 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage04.jpg');
            var img5 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage05.jpg');
            var img6 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage06.jpg');
            var img7 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage07.jpg');
            var img8 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage08.jpg');
            var img9 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage09.jpg');
            var img10 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage10.jpg');
            var img11 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage11.jpg');
            var img12 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage12.jpg');
            var img13 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage13.jpg');
            var img14 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage14.jpg');
            var img15 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage15.jpg');
            var img16 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage16.jpg');
            var img17 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage17.jpg');
            var img18 = require('../assets/img/whatsinaname/gallery/innerpage/GalleryImage18.jpg');
            var img19 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/01.webp');
            var img20 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/03.webp');
            var img21 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/04.webp');
            var img22 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/05.webp');
            var img23 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/06.webp');
            var img24 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/08.webp');
            var img25 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/10.webp');
            var img26 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/11.webp');
            var img27 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/13.webp');
            var img28 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/14.webp');
            var img29 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/15.webp');
            var img30 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/18.webp');
            var img31 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/19.webp');
            var img32 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/20.webp');
            var img33 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/21.webp');
            var img34 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/22.webp');
            var img35 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/23.webp');
            var img36 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/24.webp');
            var img37 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/25.webp');
            var img38 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/26.webp');
            var img39 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/27.webp');
            var img40 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/28.webp');
            var img41 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/29.webp');
            var img42 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/30.webp');
            var img43 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/31.webp');
            var img44 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/32.webp');
            var img45 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/33.webp');
            var img46 = require('../assets/img/whatsinaname/gallery/innerpage/NewImg/34.webp');

        return (
            <section className="gallery-one-wrapper section-bg text-white section-padding vSleepDisorderBlock">
                <div className="hero-shape service-shape">
                    <img src={require("../assets/img/shape/rmc.png")} alt="donto" className="shape shape2" />
                    <img src={require("../assets/img/shape/plusgs.png")} alt="donto" className="shape shape4" />
                    <img src={require("../assets/img/shape/plusg.png")} alt="donto" className="shape shape6" />
                    <img src={require("../assets/img/shape/plusr.png")} alt="donto" className="shape shape8" />
                    <img src={require("../assets/img/shape/sgdot.png")} alt="donto" className="shape shape9" />
                    <img src={require("../assets/img/shape/xsrdot.png")} alt="donto" className="shape shape10" />
                    <img src={require("../assets/img/shape/rmc.png")} alt="donto" className="shape s1" />
                    <img src={require("../assets/img/shape/plusgs.png")} alt="donto" className="shape s2" />
                    <img src={require("../assets/img/shape/sgdot.png")} alt="donto" className="shape s3" />
                </div>
                <div className="container">

                    <SectionTitleOne BigTitle="A Look at Our World" />

                    <div className="row">
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1" >
                                <ReactFancyBox
                                    thumbnail={img1}
                                    image={img1} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1" >
                                <ReactFancyBox
                                    thumbnail={img2}
                                    image={img2} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1" >
                                <ReactFancyBox
                                    thumbnail={img3}
                                    image={img3} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img4}
                                    image={img4} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img5}
                                    image={img5} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img6}
                                    image={img6} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img7}
                                    image={img7} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img8}
                                    image={img8} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img9}
                                    image={img9} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img10}
                                    image={img10} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img11}
                                    image={img11} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img12}
                                    image={img12} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img13}
                                    image={img13} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img14}
                                    image={img14} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img15}
                                    image={img15} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img16}
                                    image={img16} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img17}
                                    image={img17} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img18}
                                    image={img18} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img19}
                                    image={img19} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img20}
                                    image={img20} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img21}
                                    image={img21} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img22}
                                    image={img22} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img23}
                                    image={img23} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img24}
                                    image={img24} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img25}
                                    image={img25} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img26}
                                    image={img26} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img27}
                                    image={img27} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img28}
                                    image={img28} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img29}
                                    image={img29} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img30}
                                    image={img30} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img31}
                                    image={img31} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img32}
                                    image={img32} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img33}
                                    image={img33} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img34}
                                    image={img34} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img35}
                                    image={img35} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img36}
                                    image={img36} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img37}
                                    image={img37} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img38}
                                    image={img38} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img39}
                                    image={img39} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img40}
                                    image={img40} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img41}
                                    image={img41} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img42}
                                    image={img42} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img43}
                                    image={img43} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img44}
                                    image={img44} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img45}
                                    image={img45} />
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 col-12">
                            <div className="single-gallery-item single-gallery-item1">
                                <ReactFancyBox
                                    thumbnail={img46}
                                    image={img46} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default GalleryImage
