import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerContact from './BannerContact'
import SectionTitleOne from './SectionTitleOne'
import ContactAppointment from './ContactAppointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';

import { Helmet } from 'react-helmet';

class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Sleep Specialist in Hyderabad | Sleep therapeutics</title>
                    <meta name='description' content='Contact our sleep specialist in Hyderabad at Sleep Therapeutics for expert guidance and personalized solutions to improve your sleep quality.' />
                </Helmet>
                <BannerContact pageTitle='Contact' />

               

                <section className="promo-content-section promo-bg section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className='col-lg-10 offset-lg-1'>
                                <div className='row  align-items-center'>
                                <div className='col-lg-12 text-center'>
                            <div className="section-title-one">
                                <h1>Get in Touch With Us</h1>
                            </div>
                        </div>
                                <div className='clearfix'></div>
                                <div className="col-lg-5 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>Address</h2>
                                    <p>Plot no.11, Filmnagar, 8-2-293/82,<br/>
Road N0. 82, J-III, Jubilee Hills, <br/>
Hyderabad - 500 033, Telangana
</p>
<h2>Email</h2>
                                    <p><a href="mailto:info@sleeptherapeutics.in">info@sleeptherapeutics.in</a></p>
                                    
                                </div>
                            </div>
                                <div className="col-lg-7 col-12 vPB30">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.887822064753!2d78.4079296742931!3d17.417170301948218!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb96b7dc93ffff%3A0x38c949e7bb09f31f!2sSleep%20Therapeutics!5e0!3m2!1sen!2sin!4v1691250323946!5m2!1sen!2sin" width="100%" height="450" title='sleepaddress' style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                            </div>
                            
                            <div className='clearfix'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="our-achievement-wrap section-bg text-white section-padding mb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-lg-6">
                                <div className="section-text-wrap">
                                    <h1>Our Achievement</h1>
                                    <p>Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He has embraced Cosmetic Dentistry and has redesigned the smiles for thev thousands of patients.</p>
                                    <a href=".#" className="theme-btn mr-lg-3">Appointment</a>
                                    <a href=".#" className="theme-btn border-btn popup-video"><i className="fal fa-play mr-2" />Watch Video</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="achievement-funfact row">
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                end={22}
                                                duration={3.75} />
                                            <p>Patients</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={75}
                                                    duration={3.75} />
                                            <p>Dentist</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={25}
                                                    duration={3.75} />
                                            <p>Awards</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={28}
                                                    duration={3.75} />
                                            <p>Branch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                
                <ContactAppointment appointmentTitle={"Fill out the form below to communicate with us:"} /> 

            </React.Fragment>
        )
    }
}

export default Contact
