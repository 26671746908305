import React, { Component } from 'react'
import HeroOne from './Hero/HeroOne'
import FeaturesOne from './Features/FeaturesOne'
import AboutOne from './About/AboutOne'
import ServiceOne from './Service/ServiceOne'
import TeamOne from './Team/TeamOne'
import Appointment from './Appointment'
import GalleryOne from './Gallery/GalleryOne'
import TestimonailOne from './Testimonial/TestimonailOne'
import BlogOne from './Blog/BlogOne'
import Promo from './Promo'
import OurSleepSpecialist from './OurSleepSpecialist'
import SleepDisorder from './SleepDisorder'
import SleepNews from './SleepNews'
import BlogData from './Data/BlogData';
import ServiceData from './Data/ServiceData';
import FeaturesData from './Data/FeaturesData';
import TeamData from './Data/TeamData'
import { Helmet } from 'react-helmet'

class HomeOne extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Sleep Treatment Centre In Hyderabad | Sleep therapeutics</title>
                    <meta name='description' content='Enhance your sleep at the Best Sleep Centre in Hyderabad. Get personalized assessments, diagnostics, and treatments from our experts for better sleep.' />
                </Helmet>
                <HeroOne />
                <AboutOne />
                <FeaturesOne FeaturesData={FeaturesData} />
                <SleepDisorder />
                {/*<ServiceOne ServiceData={ServiceData} />*/}
                {/*<TeamOne TeamData={TeamData} />*/}
                <OurSleepSpecialist />
                <Appointment appointmentTitle={"Consult our specialists"} />
                <GalleryOne />
                <TestimonailOne />
                <SleepNews />
                <BlogOne BlogData={BlogData} />
                <Promo />
                {/*<ClientLogos />*/}
            </React.Fragment>
        )
    }
}

export default HomeOne
