import React, { Component } from 'react';

import '../assets/css/ThankYouPage.css';

class ThankYouPage extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="thank-you-wrapper section-padding">
                    <div className="container">
                        <div className="thank-you-page text-center">
                            <h1>Thank you for submitting the details!</h1>
                            <h2>We will contact you soon.</h2>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default ThankYouPage;
