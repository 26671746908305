const FeaturesData = {
    items: [
        {
            id: 1,
            imgUrl: "SleepConsultation.png",
            title: "Sleep Consultation",
            text: "Lorem Ipsum is simply is very dummy text of the printings and type setting",
        },
        {
            id: 2,
            imgUrl: "SleepDiagnostics.png",
            title: "Sleep Diagnostics",
            text: "Get our text demo is simply dummy text of the printings and type for content",
        },
        {
            id: 3,
            imgUrl: "SleepTreatment.png",
            title: "Sleep Treatment",
            text: "Lorem Ipsum is demo simply dummy text of the printings and type and setting",
        },
        {
            id: 4,
            imgUrl: "SleepHealthHygiene.png",
            title: "Sleep Health Hygiene",
            text: "Lorem Ipsum is demo simply dummy text of the printings and type and setting",
        },
        {
            id: 5,
            imgUrl: "LifestyleMentors.png",
            title: "Lifestyle Mentors",
            text: "Lorem Ipsum is demo simply dummy text of the printings and type and setting",
        }
    ],

}


export default FeaturesData
