import React, { Component } from 'react'
import CountUp from 'react-countup';
import BannerService from './BannerService';
import SectionTitleOne from './SectionTitleOne'
import Appointment from './Appointment';
import BlogOne from './Blog/BlogOne';

import BlogData from './Data/BlogData';

import { Helmet } from 'react-helmet';

class Services extends Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet='utf-8' />
                    <title>Sleep Apnea Specialist In Hyderabad | Sleep therapeutics</title>
                    <meta name='description' content='Enhance your well-being with our Sleep Apnea Specialist in Hyderabad. We offer diagnostic evaluations, specialized treatments, and therapies.' />
                </Helmet>
                <BannerService pageTitle='Services'  />

                <section className="about-page-top-content-wrap section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-8 offset-lg-2 text-center">
                                <div className="section-text-wrap">
                                    <h1>Services With a Difference</h1>
                                    <p>At Sleep Therapeutics, you’ll find world-class services under one roof. We have ensured that the best of sleep medicine from around the world is available here in Hyderabad. We have personalised services used to diagnose, treat and improve your sleep health.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                

                <section className="promo-content-section promo-bg section-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className='col-lg-10 offset-lg-1'>
                                <div className='row  align-items-center'>
                                <div className='col-lg-12 text-center'>
                            <div className="section-title-one">
                                <h1>Our Range of Services</h1>
                            </div>
                        </div>
                            <div className='clearfix'></div>
                            <div className="col-lg-6 col-12 vPB30 vDesktop">
                                <img src={require("../assets/img/whatsinaname/ourservice/SleepConsultationInner.png")} className='vDeskWidth vFloatRight' alt="" />
                            </div>
                            <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>Specialist Services</h2>
                                    <p>Our team of specialists includes pulmonologists and sleep specialists, psychiatrists, ENT surgeons, bariatric surgeons, endocrinologists, dentists, and general physicians who can provide expert guidance on sleep-related concerns.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 vPB30 vMob vServiceImg">
                                <img src={require("../assets/img/whatsinaname/ourservice/SleepConsultationInner.png")} className='vDeskWidth' alt="" />
                            </div>
                            <div className='clearfix'></div>
                            <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>Diagnostic Services</h2>
                                    <p>We offer Level 1 polysomnography, MSLT, MWT, sleep tracking, actigraphy watches, sleep sensors for use at home, pulmonary function tests, and blood investigations to help determine the root cause of your sleep issues.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 vPB30 vServiceImg">
                                <img src={require("../assets/img/whatsinaname/ourservice/DiagnosticServicesInner.png")} className='vDeskWidth' alt="" />
                            </div>
                            <div className='clearfix'></div>
                            <div className="col-lg-6 col-12 vPB30 vDesktop">
                                <img src={require("../assets/img/whatsinaname/ourservice/TreatmentServicesInner.png")} className='vDeskWidth vFloatRight' alt="" />
                            </div>
                            <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>Treatment Services</h2>
                                    <p>We offer pharmacy services to dispense prescription drugs, CPAP machines and a service centre in the PAP clinic, cognitive behaviour therapy (CBT), and various individualised programs to help you achieve better sleep.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 vPB30 vMob vServiceImg">
                                <img src={require("../assets/img/whatsinaname/ourservice/TreatmentServicesInner.png")} className='vDeskWidth' alt="" />
                            </div>
                            <div className='clearfix'></div>
                            <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>Health Hygiene Services</h2>
                                    <p>Our sleep diary and track record maintenance services help you monitor your sleep patterns. We also offer awareness programs and counselling sessions to support you throughout your sleep health journey.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 vPB30 vServiceImg">
                                <img src={require("../assets/img/whatsinaname/ourservice/HealthHygieneServicesInner.png")} className='vDeskWidth' alt="" />
                            </div>
                            <div className='clearfix'></div>
                            <div className="col-lg-6 col-12 vPB30 vDesktop">
                                <img src={require("../assets/img/whatsinaname/ourservice/LifestyleMentorsInner.png")} className='vDeskWidth vFloatRight' alt="" />
                            </div>
                            <div className="col-lg-6 col-12 vPB30">
                                <div className="promo-text mt-5 mt-lg-0 vAboutDoc">
                                    <h2>Lifestyle Mentors</h2>
                                    <p>Our dietitians, fitness coaches, and health coaches work with you to develop personalised lifestyle programs to improve your sleep and overall health.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 vPB30 vMob vServiceImg">
                                <img src={require("../assets/img/whatsinaname/ourservice/LifestyleMentorsInner.png")} className='vDeskWidth' alt="" />
                            </div>
                            <div className='clearfix'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="our-achievement-wrap section-bg text-white section-padding mb-120">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-lg-6">
                                <div className="section-text-wrap">
                                    <h1>Our Achievement</h1>
                                    <p>Smiling comes naturally to Dr. Harrie, author of ‘Donto’. He has embraced Cosmetic Dentistry and has redesigned the smiles for thev thousands of patients.</p>
                                    <a href=".#" className="theme-btn mr-lg-3">Appointment</a>
                                    <a href=".#" className="theme-btn border-btn popup-video"><i className="fal fa-play mr-2" />Watch Video</a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="achievement-funfact row">
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                end={22}
                                                duration={3.75} />
                                            <p>Patients</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={75}
                                                    duration={3.75} />
                                            <p>Dentist</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={25}
                                                    duration={3.75} />
                                            <p>Awards</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 text-center">
                                        <div className="single-am-funfact">
                                                <CountUp
                                                    end={28}
                                                    duration={3.75} />
                                            <p>Branch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>*/}
                
                <BlogOne BlogData={BlogData} />
                <Appointment appointmentTitle={"Book a Consultation"} />

            </React.Fragment>
        )
    }
}

export default Services
