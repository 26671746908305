import React, { Component } from 'react';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import { ThreeDots } from 'react-loader-spinner'; // Correct import for the loader
import '../assets/css/appointment.css'; // Assuming the CSS is here
import { Redirect } from 'react-router-dom';
 // Import Redirect for navigation
class Appointment extends Component {
  state = {
    loading: false,
    submissionSuccess: false,  // Track submission status
  };

  appendUtmParameters = (formData) => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams = ['utm_source', 'utm_keyword', 'utm_network', 'utm_campaign', 'utm_device','','utm_term','utm_medium'];

    utmParams.forEach(param => {
      const value = urlParams.get(param);
      if (value) {
        formData[param] = value ? value :"undefined"; // Appends UTM parameters dynamically
      }
    });
    formData.source = urlParams.get('utm_source') || 'Website';
    formData.utm_source=urlParams.get('utm_source') || 'Website';
    return formData;
  };


  // Define Yup validation schema
  validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    phone: Yup.string().required('Phone number is required').matches(/^\d+$/, 'Phone number must be numeric'),
    comments: Yup.string().required('Comments / Purpose is required'),
  });

  // Handle form submission
  handleSubmit = (values, { setSubmitting }) => {
    this.setState({ loading: true });

    // Map the form fields to the backend field names
    let jsonData = {
      name: values.name,
      email: values.email,
      phone: values.phone,
      additional_col1: values.comments,  // Map 'comments' to 'additional_col1'
      access_code: values.access_code,   // Using the hidden field for access_code            // Using the hidden field for source
    };

    jsonData= this.appendUtmParameters(jsonData);

    console.log('Submitting form data:', jsonData); // Log form data for debugging

    // Send form data to the CRM (Growtheye)
    axios.post('https://growtheye.com/leadApiBeta.php', jsonData)
      .then((response) => {
        console.log('CRM Response:', response.data);

        // Send the same form data to the log.php script for logging
        axios.post('https://sleeptherapeutics.in/sleep-react-backup/01-Jan-2025/log.php', jsonData)
          .then((response) => {
            console.log('Data logged successfully:', response);
          })
          .catch((error) => {
            console.error('Error logging form data', error);
          });

        // On successful submission, update state to show success and trigger redirect
        setTimeout(() => {
          this.setState({ loading: false, submissionSuccess: true });
        }, 2000);
      })
      .catch((error) => {
        console.error('Error submitting form', error);
        this.setState({ loading: false });
        alert('There was an error while submitting your form. Please try again later.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  render() {
    let { appointmentTitle, subTitle } = this.props;

    // If form submission was successful, redirect to the Thank You page
    if (this.state.submissionSuccess) {
      return <Redirect to="/thank-you" />;
    }

    return (
      <section className="appointment-wrapper section-padding" id="consult">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section-title-one">
                <h1>{appointmentTitle}</h1>
                <h2>{subTitle}</h2>
              </div>
            </div>

            <div className="col-sm-12 col-lg-6 mb-5">
              <div className="appointment-form">
                <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    phone: '',
                    comments: '',
                    access_code: 'AE45-C8A5-A80F-0894-0FF5-77B7',
                    source: 'Website',
                  }}
                  validationSchema={this.validationSchema}
                  onSubmit={this.handleSubmit}
                >
                  {({ isSubmitting, touched, errors, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="name">Name *</label>
                        <Field
                          type="text"
                          name="name"
                          id="name"
                          className="form-contro"
                        />
                        {isSubmitting && errors.name ? <div className="error">{errors.name}</div> : null}
                      </div>

                      <div className="form-group">
                        <label htmlFor="email">Email ID *</label>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          className="form-contro"
                        />
                        {isSubmitting && errors.email ? <div className="error">{errors.email}</div> : null}
                      </div>

                      <div className="form-group">
                        <label htmlFor="phone">Phone *</label>
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          className="form-contro"
                        />
                        {isSubmitting && errors.phone ? <div className="error">{errors.phone}</div> : null}
                      </div>

                      <div className="form-group">
                        <label htmlFor="comments">Comments / Purpose *</label>
                        <Field
                          as="textarea"
                          name="comments"  // Use 'comments' to match the backend field
                          id="comments"
                          className="form-control"
                        />
                        {isSubmitting && errors.comments ? <div className="error">{errors.comments}</div> : null}
                      </div>

                      <Field as="hidden" name="access_code" value="AE45-C8A5-A80F-0894-0FF5-77B7" />
                      <Field as="hidden" name="source" value="Website" />
                      <div className="form-group">
                        <button
                          type="submit"
                          className="submit-bt"
                          disabled={isSubmitting}
                        >
                          {this.state.loading ? (
                            <ThreeDots color="#fff" height={20} width={20} />
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="col-lg-6">
              <img
                src={require("../assets/img/whatsinaname/ConsultSpecialists.png")}
                alt="Consult Specialists"
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Appointment;
